import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  GetAccesories,
  CreateAccesories,
  UpdateAccessories,
  GetProvider,
  GetTypes
} from 'services/providers';
import { GET_ACTIVE_LOCATIONS,me } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import { Table, Form, Button, Modal, Switch  , Input, InputNumber ,Select } from 'antd';
import { notify } from 'utilities';

const { Item } = Form;
const Accessories = () => {
  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [createAccessorieVisiable, SetCreateAccessorieVisiable] = useState(
    false
  );
  const [UpdateAccessorieVisible, SetUpdateAccessorieVisible] = useState(false);
  const { data, loading, fetchMore, refetch,error } = useQuery(GetAccesories, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [page, setPage] = useState(1);
  const [updateAccessories, { loading: updateLoading }] = useMutation(
    UpdateAccessories
  );
  const [createAccessorie, { loading: creating }] = useMutation(
    CreateAccesories
  );
  const { data:providerData } = useQuery(GetProvider, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: typesData } = useQuery(GetTypes, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: locations } = useQuery(GET_ACTIVE_LOCATIONS );
  const { activeLocations } = locations || { activeLocations: [] };
  const [createForm] = Form.useForm();
  const [updateForm] = Form.useForm();
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Code - serial number',
      dataIndex: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Provider',
      dataIndex: ['provider', 'name'],
    },
    {
      title: 'Location',
      dataIndex: ['location', 'name_en'],
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Price',
      dataIndex: 'price',
    },
    {
      title: 'Sold Price',
      dataIndex: 'sold_price',
    },
    {
      title: 'Total Cost',
      dataIndex: 'total_cost',
    },
    {
      title: 'created at',
      dataIndex: 'created_at',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
    },
    {
      title: 'Action',
      render: (data) => {
        return (
          <Button
            onClick={() => {
              SetUpdateAccessorieVisible(true);
              updateForm.setFieldsValue({
                id: data.id,
                name: data.name,
                price:data.price,
                type:data.type,
                sold_price: data.sold_price,
                provider: data.provider.id,
                description: data.description,
                code: data.code
              });
            }}
          >
            Update Accesorie
          </Button>
        );
      },
    },
  ];
  const handelCreateAccessorie = () => {
    createForm.validateFields().then((values) => {
      createAccessorie({
        variables: {
          ...values,
          user_id : userData?.me?.id
        },
      })
        .then((response) => {
          const {
            data: {
              createAccessories: { status, message },
            },
          } = response;
          if(status){
            notify('success', message);
            SetCreateAccessorieVisiable(false);
            refetch()
            
          }else{
            notify('error', message);
          }
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateAccessorie = () => {
    updateForm.validateFields().then((values) => {
      updateAccessories({
        variables: {
          ...values,
          user_id : userData?.me?.id,
          location_id: +values.location_id
        },
      })
        .then((response) => {
          const {
            data: {
              updateAccessories: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetUpdateAccessorieVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>Accessories</PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            createForm.resetFields();
            SetCreateAccessorieVisiable(true);
          }}
        >
          Create Accessories
        </PrimaryButton>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={data && data.accessories.data}
        columns={columns}
        loading={loading || updateLoading || creating}
        rowKey="id"
        pagination={{
          total: data?.accessories?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title="Create Accessorie"
        visible={createAccessorieVisiable}
        okText="Create"
        onOk={handelCreateAccessorie}
        onCancel={() => {
          SetCreateAccessorieVisiable(false);
        }}
      >
        <Form onFinish={handelCreateAccessorie} form={createForm}>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.name}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: 'Please enter the price',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="sold_price"
            label="Sold Price"
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="location_id"
            label="Location"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="provider_id"
            label="Provider"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
      <Modal
        title="Update Provider Accessories"
        visible={UpdateAccessorieVisible}
        okText="Update"
        onOk={handelUpdateAccessorie}
        onCancel={() => {
          SetUpdateAccessorieVisible(false);
        }}
      >
        <Form onFinish={handelUpdateAccessorie} form={updateForm}>
          <Item
            name="id"
            label="Id"
            rules={[
              {
                message: 'Please enter id',
              },
            ]}
          >
            <Input  disabled={true} ></Input>
          </Item>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Describtion"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.name}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: 'Please enter the price',
              },
            ]}
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="sold_price"
            label="Sold Price"
          >
            <InputNumber></InputNumber>
          </Item>
          <Item
            name="location_id"
            label="Location"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="provider_id"
            label="Provider"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Accessories;
