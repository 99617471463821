import gql from 'graphql-tag';

export const GetProvider = gql`
  query providers($first: Int!, $page: Int) {
    providers(
      first: $first
      page: $page
      orderBy: { field: "id", order: DESC }
    ) {
      paginatorInfo {
        count
        total
      }
      data {
        id
        code
        name
        phone
        email
        credit
        description
        debit
        created_at
        updated_at
        address
        attachments {
          name
          link
        }
      }
    }
  }
`;

export const CreateProvider = gql`
  mutation createProvider(
    $code: String!
    $name: String!
    $description: String
    $location: Int!
    $phone: String!
    $email: String!
    $address: String!
    $description: String!
    $attachments: [Upload]
  ) {
    createProvider(
      code: $code
      name: $name
      phone: $phone
      email: $email
      address: $address
      description: $description
      attachments: $attachments
    ) {
      status
      message
    }
  }
`;

export const UpdateProvider = gql`
  mutation updateProvider(
    $id: ID!
    $code: String!
    $name: String!
    $phone: String!
    $email: String!
    $address: String!
     $description: String!
    $attachments: [Upload]
  ) {
    updateProvider(
      id: $id
      code: $code
      name: $name
      phone: $phone
      email: $email
      address: $address
      description: $description
      attachments: $attachments
    ) {
      status
      message
    }
  }
`;
export const ProviderItems = gql`
  query providersItems($first: Int!, $page: Int) {
    providersItems(first: $first, page: $page) {
       paginatorInfo {
        count
        total
      }
      data {
        code
        id
        price
        name
        description
        location
        item_type{
        id
        name
        }
        total_cost
        accessories_price
        sold_accessories_price
        current_year_depreciation
        Previous_years_depreciation
        total_depreciation
        months_of_depreciation
        net_cost
        provider{
        id
        name
        }
        created_at
        updated_at
        tax_deprecation
        accessories{
        id
        name
        price
        }
      }
    }
  }
`;
export const CreatProviderItem = gql`
  mutation createProviderItem(
    $code: String!
    $name: String!
    $description: String
    $location: Int!
    $type: Int!
    $price: String
    $provider_id:Int!
    $accessories: [ID]
  ) {
    createProviderItem(
      code: $code
      name: $name
      description: $description
      location: $location
      type: $type
      price: $price
      provider_id : $provider_id
      accessories: $accessories
    ){
     status
    message 
}
    }
    `;
export const UpdateProviderItem = gql`
  mutation updateProviderItem(
    $id: ID!
    $code: String!
    $name: String!
    $description: String
    $location: Int!
    $type: Int!
    $price: String
    $provider_id:Int!
    $accessories: [ID]!
  ) {
    updateProviderItem(
      id: $id
      code: $code
      name: $name
      description: $description
      location: $location
      type: $type
      price: $price
      provider_id : $provider_id
      accessories: $accessories
    ){
     status
    message 
}
    }
    `;
export const CreateAccesories = gql`
  mutation createAccessories(
    $name: String!
    $price: Float!
    $location_id: Int!
    $user_id: Int!
    $type: String!
    $sold_price: Float
    $provider_id: Int!
    $code: String!
    $description: String!
  ) {
    createAccessories(
    name: $name
    price: $price
    location_id: $location_id
    user_id: $user_id
    type: $type
    sold_price: $sold_price
    provider_id: $provider_id
    code:  $code
    description: $description
    ){
     status
    message 
}
    }
    `;

    export const UpdateAccessories = gql`
  mutation updateAccessories(
  $id: ID!
  $name: String!
  $price: Float
  $location_id: Int
  $user_id: Int
  $type: String
  $sold_price: Float
  $provider_id: Int
  $code: String!
  $description: String!
  ) {
    updateAccessories(
    id: $id
    name: $name
    price: $price
    location_id : $location_id
    user_id: $user_id
    type: $type
    sold_price: $sold_price
    provider_id:  $provider_id
    code:  $code
    description: $description
    
    ) {
      status
      message
    }
  }
`;
    export const GetAccesories = gql`
      query accessories($first: Int!, $page: Int) {
        accessories(first: $first, page: $page) {
          paginatorInfo {
            count
            total
          }
          data {
            id
            name
            price
            total_cost
            sold_price
            created_at
            updated_at
            code
            description
            type
            location {
            id
            name_en
            }
            user{
            id
            name
            }
          provider{
          id
          name
          }
          }
        }
      }
    `;


  export const GetTypes = gql`
  query accessoriesTypes($first: Int!, $page: Int) {
    accessoriesTypes(first: $first, page: $page) {
       paginatorInfo {
        count
        total
      }
      data {
        id
        name
        description
        created_at
        updated_at
        depreciation_ratio
      }
    }
  }
`;
export const CreateType = gql`
  mutation createAccessoriesType(
    $name: String!
    $description: String!
    $depreciation_ratio: Float!
  ) {
    createAccessoriesType(
      name: $name
      description: $description
      depreciation_ratio: $depreciation_ratio
    ) {
      status
      message
    }
  }
`;
export const UpdateType = gql`
  mutation updateAccessoriesType(
    $id: ID!
    $name: String!
    $description: String!
    $depreciation_ratio: Float!
  ) {
    updateAccessoriesType(
      id: $id
      name: $name
      description: $description
      depreciation_ratio: $depreciation_ratio
    ) {
      status
      message
    }
  }
`;
