const paymentMethods = [
  'Cash',
  'StoreCash',
  'StoreCard',
  'PartnerCredit',
  'DriverCash',
  'AutoConfirmed',
  'Card',
  'Fawry',
  'Aman',
  'Wallet',
  'Agent',
  'Link',
];
const Agents = ['ahly momkn', 'sadad', 'ekhales'];

export { paymentMethods, Agents };
