import React, { useState } from 'react';
import { useQuery, useMutation } from 'react-apollo';
import {
  ProviderItems,
  CreatProviderItem,
  UpdateProviderItem,
  GetAccesories,
  GetTypes,
  GetProvider
} from 'services/providers';
import { GET_ACTIVE_LOCATIONS } from 'services';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import {  Table, Form, Button, Modal,  Input, Select} from 'antd';
import { notify } from 'utilities';


const { Item } = Form;
const Items = () => {
  const [createProviderItemVisiable, SetCreateProviderItemVisiable] = useState(
    false
  );
  const [UpdateItemsVisible, SetUpdateItemsVisible] = useState(false);
  const { data, loading, fetchMore, refetch } = useQuery(ProviderItems, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: accessories } = useQuery(GetAccesories, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data: locations } = useQuery(GET_ACTIVE_LOCATIONS );
  const { activeLocations } = locations || { activeLocations: [] };
  const [page, setPage] = useState(1);
  const [updateProviderItem, { loading: updateLoading }] = useMutation(
    UpdateProviderItem
  );
  const [createProviderItem, { loading: creating }] = useMutation(
    CreatProviderItem
  );
  const { data: typesData } = useQuery(GetTypes, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const { data:providerData } = useQuery(GetProvider, {
    variables: {
      first: 100,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [updateForm ] = Form.useForm();
  const [createForm ] = Form.useForm();

  const accessoriesArray = accessories&&accessories['accessories']['data'];
  const accesoriesOptions = []
  for (let i = 0; i < accessoriesArray?.length; i++) {
    accesoriesOptions.push({
      label:accessoriesArray[i]?.name,
      value:  accessoriesArray[i]?.id,
    });
  }
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: 'Code - serial number',
      dataIndex: 'code',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
    {
      title: 'Provider',
      dataIndex: ['provider', 'name'],
    },
    {
      title: 'Months of Depreciation',
      dataIndex: 'months_of_depreciation',
    },
    {
      title: 'location',
      dataIndex: 'location',
    },
    {
      title: 'Type',
      dataIndex: ['item_type' , 'name'],
    },
    {
      title: 'item price',
      dataIndex: 'price',
    },
    {
      title: 'Accessories',
      dataIndex: 'accessories',
      render : (accessories)=> <>
      {accessories.map(acc=>(
        <p>{acc.name}</p>
      ))}
      </>
    },
    {
      title: 'Accesserios price',
        dataIndex : 'accessories_price'
    },
    {
      title: 'Sold acceserios price',
      dataIndex: 'sold_accessories_price',
    },
    {
      title: 'Total Cost',
      dataIndex:'total_cost'
    },
    {
      title: 'Deprication Of Current Year',
      dataIndex: 'current_year_depreciation',
    },
    {
      title: 'Deprication Of Pervious Years',
      dataIndex: 'Previous_years_depreciation',
    },
    {
      title: 'Total Deprications',
      dataIndex: 'total_depreciation',
    },
    {
      title: 'Net Cost',
      dataIndex: 'net_cost',
    },
    {
      title: 'Tax Deprication',
      dataIndex: 'tax_deprecation',
    },
    {
      title: 'Updated At',
      dataIndex: 'updated_at',
    },
    {
      title: 'Action',
      render: (data) => {
        return (
          <Button
            onClick={() => {
              SetUpdateItemsVisible(true);
              const restructureAccessories = data.accessories.reduce(
                (acc, current) => {
                  acc.push(current.id);
                  return acc;
                },[]);
              updateForm.setFieldsValue({
                id: data.id,
                code: data.code,
                name: data.name,
                price: data.price,
                location: data.location,
                type: data.item_type.id,
                description: data.description,
                provider_id: data.provider['id'],
                accessories: restructureAccessories,
              });
            }}
          >
            Update Item
          </Button>
        );
      },
    },
  ];
  const handelCreateProviderItem = () => {
    createForm.validateFields().then((values) => {
      createProviderItem({
        variables: {
          ...values,
        },
      })
        .then((response) => {
          const {
            data: {
              createProviderItem: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetCreateProviderItemVisiable(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateItems = () => {
    updateForm.validateFields().then((values) => {
      updateProviderItem({
        variables: {
          ...values,
        },
      })
        .then((response) => {
          const {
            data: {
              updateProviderItem: { status, message },
            },
          } = response;
          refetch();
          notify('success', message);
          SetUpdateItemsVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  return (
    <>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>Items</PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            SetCreateProviderItemVisiable(true);
          }}
        >
          Create Item
        </PrimaryButton>
      </ListHeader>
      <Table
        scroll={{ x: 400 }}
        dataSource={data && data.providersItems.data}
        columns={columns}
        loading={loading || updateLoading || creating}
        rowKey="id"
        pagination={{
          total: data?.providersItems?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title="Create Provider Item"
        visible={createProviderItemVisiable}
        okText="Create"
        onOk={handelCreateProviderItem}
        onCancel={() => {
          SetCreateProviderItemVisiable(false);
        }}
      >
        <Form onFinish={handelCreateProviderItem} form={createForm}>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="provider_id"
            label="Provider"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="accessories"
            label="Accessories"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={accesoriesOptions}
            />
          </Item>
        </Form>
      </Modal>
      <Modal
        title="Update Provider Item"
        visible={UpdateItemsVisible}
        okText="Update"
        onOk={handelUpdateItems}
        onCancel={() => {
          SetUpdateItemsVisible(false);
        }}
      >
     <Form onFinish={handelUpdateItems} form={updateForm}>
          <Item
            name="id"
            label="Id"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input disabled></Input>
          </Item>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="location"
            label="Location"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {activeLocations.map((location, idx) => (
                <Select.Option key={idx} value={location.id}>
                  {location.name_en}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="type"
            label="Type"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {typesData&&typesData.accessoriesTypes.data.map((type, idx) => (
                <Select.Option key={idx} value={type.id}>
                  {type.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="price"
            label="Price"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="provider_id"
            label="Provider"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select>
              {providerData&&providerData.providers.data.map((provider, idx) => (
                <Select.Option key={idx} value={provider.id}>
                  {provider.name}
                </Select.Option>
              ))}
            </Select>
          </Item>
          <Item
            name="accessories"
            label="Accessories"
            rules={[
              {
                required: true,
                message: 'please fill out this field',
              },
            ]}
          >
            <Select
              mode="multiple"
              value={['19','20']}
              allowClear
              style={{
                width: '100%',
              }}
              placeholder="Please select"
              options={accesoriesOptions}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

export default Items;
