import React, { useState } from 'react';
import { ListHeader, PrimaryTitle, PrimaryButton } from 'components';
import {
  Space,
  Table,
  Form,
  Button,
  Modal,
  Upload,
  Input,
  Divider,
} from 'antd';
import { useQuery, useMutation } from '@apollo/react-hooks';
import {
  GetProvider,
  CreateProvider,
  UpdateProvider,
} from 'services/providers';
import styled from '@emotion/styled';
import { notify } from 'utilities';
import { UploadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const ProviderContainer = styled.div``;
const { Item } = Form;
const Providers = () => {
  const [createProviderVisible, SetCreateProviderVisible] = useState(false);
  const [UpdateProviderVisible, SetUpdateProviderVisible] = useState(false);
  const [activeProvider, setActiveprovider] = useState(false);
  const { data, loading, fetchMore, refetch } = useQuery(GetProvider, {
    variables: {
      first: 5,
      page: 1,
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const [files, setFiles] = useState([]);
  const [updateForm, createForm] = Form.useForm();
  const [page, setPage] = useState(1);
  const [createProvider, { loading: updating }] = useMutation(CreateProvider);
  const [updateProvider, { loading: updateLoading }] = useMutation(
    UpdateProvider
  );
  const handelCreateProvider = () => {
    createForm.validateFields().then((values) => {
      createProvider({
        variables: {
          ...values,
          attachments: files,
        },
      })
        .then((response) => {
          refetch();
          notify('success', response?.data?.createProvider.message);
          SetCreateProviderVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const handelUpdateProvider = () => {
    updateForm.validateFields().then((values) => {
      updateProvider({
        variables: {
          ...values,
          id: activeProvider.id,
          attachments: files,
        },
      })
        .then((response) => {
          refetch();
          notify('success', response?.data?.updateProvider?.message);
          SetUpdateProviderVisible(false);
        })
        .catch((err) => {
          if (err['graphQLErrors'][0]?.extensions) {
            const {
              extensions: { validation },
              message,
            } = err['graphQLErrors'][0];
            if (validation) {
              for (let error in validation) {
                notify('error', validation[error][0]);
              }
            } else {
              notify('error', message);
            }
          }
        });
    });
  };
  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      title: 'Attachment',
      render: (provider) => {
        return provider.attachments?.map((file, index) => (
          <>
            <Divider type="vertical" />
            <a href={file.link} target="_blank">
              {file.name}
            </a>
          </>
          // <p key={index}>{newTicket.id}</p>
        ));
      },
    },
    {
      title: 'Debit',
      dataIndex: 'debit',
    },
    {
      title: 'Credit',
      dataIndex: 'credit',
    },
    {
      title: 'Total',
      dataIndex: 'total',
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
    },
    {
      title: 'Updatd At',
      dataIndex: 'updated_at',
    },
    {
      title: 'Action',
      render: (data) => {
        return (
          <Button
            onClick={() => {
              setActiveprovider(data);
              SetUpdateProviderVisible(true);
              setFiles(undefined);
              updateForm.setFieldsValue({
                id: data.id,
                code: data.code,
                name: data.name,
                email: data.email,
                phone: data.phone,
                description: data.description,
                address: data.address,
              });
            }}
          >
            Update Provider
          </Button>
        );
      },
    },
  ];

  const handleFileChange = (event) => {
    setFiles(event.target.files);
  };

  return (
    <ProviderContainer>
      <ListHeader>
        <PrimaryTitle style={{ flex: '1 1 0' }}>Providers</PrimaryTitle>
        <PrimaryButton
          onClick={() => {
            SetCreateProviderVisible(true);
            setFiles(undefined);
          }}
        >
          Create Provider
        </PrimaryButton>
      </ListHeader>
      <Table
        dataSource={data && data.providers.data}
        columns={columns}
        loading={loading || updating || updateLoading}
        rowKey="id"
        pagination={{
          total: data?.providers?.paginatorInfo?.total,
          pageSize: 5,
          showSizeChanger: false,
          current: page,
          onChange: (page) => {
            setPage(page);
            fetchMore({
              variables: {
                page,
              },
              updateQuery: (prev, { fetchMoreResult }) =>
                fetchMoreResult ? fetchMoreResult : prev,
            });
          },
        }}
      />
      <Modal
        title="Create Provider"
        visible={createProviderVisible}
        okText="Create"
        onOk={handelCreateProvider}
        onCancel={() => {
          SetCreateProviderVisible(false);
        }}
      >
        <Form onFinish={handelCreateProvider} form={createForm}>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'Please enter provider code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter provider name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please enter provider name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: 'Please enter provider phone',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter provider email',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: 'Please enter provider adrress',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <input type="file" multiple onChange={handleFileChange} />
        </Form>
      </Modal>
      <Modal
        title="Update Provider"
        visible={UpdateProviderVisible}
        okText="Update"
        onOk={handelUpdateProvider}
        onCancel={() => {
          SetUpdateProviderVisible(false);
        }}
      >
        <Form onFinish={handelUpdateProvider} form={updateForm}>
          <Item
            name="code"
            label="Code"
            rules={[
              {
                required: true,
                message: 'Please enter provider code',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: 'Please enter provider name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="description"
            label="Description"
            rules={[
              {
                required: true,
                message: 'Please enter provider name',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="phone"
            label="Phone"
            rules={[
              {
                required: true,
                message: 'Please enter provider phone',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="email"
            label="Email"
            rules={[
              {
                required: true,
                message: 'Please enter provider email',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <Item
            name="address"
            label="Address"
            rules={[
              {
                required: true,
                message: 'Please enter provider adrress',
              },
            ]}
          >
            <Input></Input>
          </Item>
          <input type="file" multiple onChange={handleFileChange} />
        </Form>
      </Modal>
    </ProviderContainer>
  );
};

export default Providers;
