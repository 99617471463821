import React, { useState, useMemo, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import styled from '@emotion/styled/macro';

import { CreateOrder, GetCart, me } from 'services';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
  Modal,
  Row,
  Col,
  Radio,
  InputNumber,
  Empty,
  Spin,
  Button,
  notification,
  Input,
  Checkbox,
} from 'antd';

import { Can } from 'components';
import { useHistory } from 'react-router-dom';
import { getFawryChargeRequest, notify } from 'utilities';
import toggleOpenRound from '../../../services/tickets/toggleOpenRound';
import { Empty_Cart } from '../../../services/ticketing/emptyCartTickets';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const CartContainer = styled.div`
  border: lightgray solid 1px;
  box-shadow: 5px 5px 10px lightgrey;
  padding: 20px;
  margin-top: 20px;
  text-align: center;
  display: inline-block;

  h2 {
    font-size: 28px;
    margin-bottom: 30px;
    border-bottom: 1px solid;
    display: inline-block;
    padding-bottom: 18px;
  }
`;

const TicketContainer = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px dashed lightgray;

  :first-of-type {
    padding-top: 0;
  }
`;

const SeatContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Information = styled.div`
  margin-right: 40px;
`;

const TotalContainer = styled.div`
  display: flex;
  margin: 10px 0;

  div {
    flex: 1 1 0px;

    :last-of-type {
      margin-right: 0;
    }
  }
`;

const Seat = styled.div`
  display: flex;
  padding: 10px 0;
  border-bottom: 1px dashed lightgray;

  :last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }

  :first-of-type {
    padding-top: 0;
  }
`;

const Label = styled.p`
  font-weight: 500;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 7px;
`;

const ButtonWrapper = styled.div`
  display: inline-block;
  width: 40%;
  height: 50px;

  button {
    width: 100%;
    height: 100%;
    font-size: 18px;
    color: green;
    border-color: green;
  }
`;
const OptionWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

const OldPrice = styled.div`
  color: ${(props) => props.color};
  text-decoration: ${(props) => props.textDecoration};
`;

const Cart = ({
  currentCart,
  removeSelectedSeat,
  cartToken,
  selectedCustomer,
  restructureTickets,
  editTicket,
  setEditTicket,
  replaceOrder,
  setCurrentCart,
  setSelectedSeats,
  form: { getFieldDecorator, validateFields },
  bulk,
  upgrade,
}) => {
  const [t, i18n] = useTranslation();
  const [visible, setVisible] = useState(false);
  const [value, setValue] = useState('StoreCash');
  const [transactionN, setTransactionN] = useState(null);
  const [openCartActive, setOpenCartActive] = useState(false);
  const [lastDate, setLastDate] = useState(null);
  const [createOrder, { loading }] = useMutation(CreateOrder);
  const [toggleOpenCart, { loading: loadingOpen }] = useMutation(
    toggleOpenRound
  );
  const [getCart, { loading: getCartLoading }] = useMutation(GetCart);
  const [emptyCart, { loading: emptyCartLoading }] = useMutation(Empty_Cart);
  const history = useHistory();
  const deductionAmount = editTicket && editTicket.deduction_amount;
  const ticketPrice = editTicket && editTicket.ticket_price;
  const color = deductionAmount ? 'red' : 'unset';
  const textDecoration = deductionAmount ? 'line-through' : 'none';
  const language = i18next.language;

  const updateCart = () => {
    getCart({
      variables: {
        cartToken,
      },
    })
      .then((cart) => {
        setCurrentCart(cart?.data?.getCart);
      })
      .catch((err) => {
        window.location.reload();
      });
  };
  const handleOk = (e) => {
    e.preventDefault();

    if (bulk && editTicket.ticket_id.length !== currentCart.tickets.length) {
      //compare the edited tickets to the booked tickets
      notify(
        'error',
        `You need to book exactly ${editTicket.ticket_id.length} tickets`
      );
    } else {
      validateFields((err, values) => {
        if (!err) {
          const { transactionId } = values;
          createOrder({
            variables: {
              cartToken,
              customerId: selectedCustomer.id,
              paymentMethod: value,
              transactionId: transactionN,
              adminOrder: true,
              upgrade: upgrade ? true : false, //true or false
              ...(editTicket && {
                editTicket: {
                  deduction_amount: editTicket?.deduction_amount,
                  ticket_id: editTicket.ticket_id,
                  bulk: bulk, //true or false
                },
              }),
            },
          })
            .then((res) => {
              const {
                data: {
                  createAdminOrder: {
                    payment_token: paymentToken,
                    message_signature: signature,
                    status,
                    order,
                    edit_ticket,
                    message,
                  },
                },
              } = res;
              if (status === false && message) {
                notify('error', message);
              }
              if (status) {
                if (value === 'Fawry') {
                  const { id: orderId, order_number: orderNumber } = order;
                  const fawryLanguage = 'en-gb';

                  const fawryChargeRequest = getFawryChargeRequest(
                    fawryLanguage,
                    orderId,
                    orderNumber,
                    totalPrice,
                    {
                      name: selectedCustomer.name,
                      mobile: selectedCustomer.phone,
                      email: selectedCustomer.email,
                    },
                    signature
                  );

                  history.push({
                    pathname: 'payment',
                    state: {
                      paymentOption: value,
                      fawryChargeRequest,
                    },
                  });
                } else {
                  notification['success']({
                    message: 'Order Success',
                    description: 'Your order has been created successfully',
                  });

                  window.location.href = `/order/${order.id}`;
                }
              } else if (
                !status &&
                edit_ticket.deduction_amount !== deductionAmount
              ) {
                const newAmount = edit_ticket.deduction_amount;
                Modal.confirm({
                  title: t('Warning: Deduction amount has changed!'),
                  content: `${t(
                    'The new deduction amount is'
                  )} ${newAmount}. ${t('It was')} ${deductionAmount} ${t(
                    'before'
                  )}.`,
                  okText: t('Confirm'),
                  cancelText: t('Cancel'),
                  onOk() {
                    setEditTicket({
                      ...editTicket,
                      deduction_amount: newAmount,
                    });
                  },
                  onCancel() {
                    window.location.href = `/ticketing?ticketId=${editTicket.ticket_id}`;
                  },
                });
              } else {
                notification['error']({
                  message: 'Order Failure',
                  description: 'Your order was not successful',
                });
              }
            })
            .catch((err) => {
              if (err['graphQLErrors'] && err['graphQLErrors'][0]?.extensions) {
                const {
                  extensions: { validation },
                  message,
                } = err['graphQLErrors'][0];
                if (validation) {
                  for (let error in validation) {
                    notify('error', validation[error][0]);
                  }
                } else {
                  notify('error', t('payCashOnly'));
                }
              }
              if (setSelectedSeats) {
                setSelectedSeats({});
              }
              updateCart();
            });

          setVisible(false);
        }
      });
    }
  };

  const ticketsPerTrip =
    currentCart &&
    currentCart.tickets &&
    currentCart.tickets.length > 0 &&
    restructureTickets(currentCart.tickets);

  const calculateTicketsPrice = (groupedTickets, key) =>
    Object.keys(groupedTickets).reduce(
      (accumulator, groupId) => accumulator + groupedTickets[groupId][key],
      0
    );

  const groupedTickets = useMemo(
    () => restructureTickets(currentCart.tickets),
    [currentCart.tickets, restructureTickets]
  );

  const totalPrice = useMemo(
    () => calculateTicketsPrice(groupedTickets, 'groupPrice'),
    [groupedTickets]
  );

  const originalTotalPrice = useMemo(
    () => calculateTicketsPrice(groupedTickets, 'originalGroupPrice'),
    [groupedTickets]
  );

  const isPromocodeApplied = totalPrice !== originalTotalPrice;

  var refundAmount = ticketPrice - deductionAmount;
  if (upgrade) refundAmount = ticketPrice;
  const newPrice = (totalPrice - refundAmount).toFixed(2);
  const { data: userData } = useQuery(me, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });
  const handleCheckbox = (seat, e) => {
    if (e.target.checked) setOpenCartActive(true);
    toggleOpenCart({ variables: { id: seat.ticketId, open: e.target.checked } })
      .then((response) => {
        const {
          data: {
            toggleOpenRound: { status, message },
          },
        } = response;
        if (status) {
          setOpenCartActive(true);
          notify('success', message);
        }
      })
      .catch((err) => {
        if (err['graphQLErrors'] && err['graphQLErrors'][0]?.extensions) {
          const {
            extensions: { validation },
            message,
          } = err['graphQLErrors'][0];
          if (validation) {
            for (let error in validation) {
              notify('error', validation[error][0]);
            }
          } else {
            notify('error', t(message));
          }
        }
      });
  };
  const emptyCartTickets = () => {
    emptyCart({
      variables: {
        cartToken: cartToken,
      },
    }).finally(() => {
      window.location.reload();
    });
  };
  useEffect(() => {
    if (Object.keys(ticketsPerTrip).length > 1) {
      console.info(currentCart.tickets);
      const maxDate = currentCart.tickets.reduce((a, b) =>
        a.from_date > b.from_date ? a : b
      );

      // let latestOne =Math.max(...currentCart.tickets.map(ticket =>{return  new Date(ticket.from_date)}))

      console.info('latestOne');
      console.info(maxDate);
      if (openCartActive && !maxDate) {
        emptyCartTickets();
      }

      if (maxDate) {
        if (lastDate && maxDate.from_date == lastDate) {
          return;
        }
        if (lastDate && maxDate.from_date != maxDate && openCartActive)
          emptyCartTickets();

        setLastDate(maxDate.from_date);
      }
    }
  }, [currentCart, emptyCartTickets, lastDate, openCartActive, ticketsPerTrip]);
  console.info(currentCart.tickets);
  return (
    <>
      <Radio.Group onChange={(e) => setValue(e.target.value)} value={value}>
        {/* <Can
          perform="PAY_WITH_FAWRY"
          yes={<Radio value="Fawry">{t("Fawry")}</Radio>}
        ></Can> */}

        <Can
          perform="PAY_WITH_AMAN"
          yes={<Radio value="Aman">{t('Aman')}</Radio>}
        ></Can>

        <Can
          perform="PAY_CASH_AT_STORE"
          yes={<Radio value="StoreCash">{t('Pay Now (Cash)')}</Radio>}
        ></Can>

        <Can
          perform="PAY_CARD_AT_STORE"
          yes={<Radio value="StoreCard">{t('Pay Now (Credit Card)')}</Radio>}
        ></Can>
        <Can
          perform="PAY_WITH_LINK"
          yes={<Radio value="Link">{t('Payment Link')}</Radio>}
        ></Can>

        <OptionWrapper>
          <Can perform="PAY_CASH" yes={<Radio value="Cash"></Radio>}></Can>
          <ul style={{ padding: '0px 20px' }}>
            <li>{t('Pay at store')}</li>
            <li>{t('Al Ahly momkn')}</li>
            <li>{t('Sadad')}</li>
          </ul>
        </OptionWrapper>
      </Radio.Group>

      <Form layout="inline">
        <Form.Item label={t('Transaction Number')}>
          {getFieldDecorator('transactionNumber', {
            rules: [
              {
                required: !transactionN && value === 'StoreCard',
                message: 'Please enter transaction number',
              },
            ],
          })(
            <Input
              onChange={(e) => {
                setTransactionN(e.target.value);
              }}
              placeholder={t('Enter transaction number')}
              disabled={value !== 'StoreCard'}
            ></Input>
          )}
        </Form.Item>
      </Form>

      {currentCart && currentCart.tickets.length > 0 && (
        <CartContainer>
          <h2>{t('Shopping Cart')}</h2>
          {(() => {
            if (
              currentCart &&
              currentCart.tickets &&
              currentCart.tickets.length
            ) {
              return (
                <>
                  {ticketsPerTrip ? (
                    Object.keys(ticketsPerTrip).map(
                      (groupTicketId, ticketIdx) => {
                        const tripDetails = ticketsPerTrip[groupTicketId];
                        const {
                          fromLocationName,
                          toLocationName,
                          fromLocationName_ar,
                          toLocationName_ar,
                          seats,
                          from_time,
                          from_date,
                        } = tripDetails;
                        return (
                          <TicketContainer key={ticketIdx}>
                            <Information>
                              <Label>{t('From')}</Label>
                              <p>
                                {i18next.language === 'en'
                                  ? fromLocationName
                                  : fromLocationName_ar}
                              </p>
                            </Information>
                            <Information>
                              <Label>{t('To')}</Label>
                              <p>
                                {i18next.language === 'en'
                                  ? toLocationName
                                  : toLocationName_ar}
                              </p>
                            </Information>
                            <Information>
                              <Label>{t('Date')}</Label>
                              <p>{t(from_date)}</p>
                            </Information>
                            <Information>
                              <Label>{t('Time')}</Label>
                              <p>{from_time}</p>
                            </Information>
                            <SeatContainer>
                              {seats.map((seat, seatId) => {
                                const {
                                  seatNumber,
                                  SeatTypeName,
                                  price,
                                  original_price,
                                } = seat;

                                return (
                                  <Seat key={seatId}>
                                    <Information>
                                      <Label>{t('Seat Type')}</Label>
                                      <p>{t(SeatTypeName)}</p>
                                    </Information>
                                    <Information>
                                      <Label>{t('Seat Number')}</Label>
                                      <p>{seatNumber}</p>
                                    </Information>

                                    {isPromocodeApplied &&
                                    original_price != price ? (
                                      <>
                                        <Information>
                                          <Label>{t('Price')}</Label>
                                          <OldPrice
                                            color="red"
                                            textDecoration="line-through"
                                          >
                                            {original_price}
                                          </OldPrice>
                                        </Information>
                                        <Information>
                                          <Label>
                                            {t('Price after Discount')}
                                          </Label>
                                          <p>{price}</p>
                                        </Information>
                                      </>
                                    ) : (
                                      <Information>
                                        <Label>{t('Price')}</Label>
                                        <p>{price}</p>
                                      </Information>
                                    )}
                                    <Button
                                      type="danger"
                                      onClick={() => {
                                        removeSelectedSeat(
                                          String(seatNumber),
                                          groupTicketId
                                        );
                                      }}
                                    >
                                      {t('Remove')}
                                    </Button>

                                    {lastDate && seat.from_date == lastDate ? (
                                      <Checkbox
                                        style={{ marginLeft: '30px' }}
                                        onChange={(e) => {
                                          handleCheckbox(seat, e);
                                        }}
                                      >
                                        {' '}
                                        open rounded{' '}
                                      </Checkbox>
                                    ) : (
                                      ''
                                    )}
                                  </Seat>
                                );
                              })}
                            </SeatContainer>
                          </TicketContainer>
                        );
                      }
                    )
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )}
                  <TotalContainer>
                    {deductionAmount ? (
                      <>
                        <Information>
                          <Label>{t('Original Order Price')}</Label>
                          <OldPrice
                            color={color}
                            textDecoration={textDecoration}
                          >
                            {editTicket.ticket_price}
                          </OldPrice>
                        </Information>
                        <Information>
                          <Label>{t('Deduction Amount')}</Label>
                          <p>{deductionAmount}</p>
                        </Information>
                        <Information>
                          <Label>{t('Refund Amount')}</Label>
                          <p>{refundAmount}</p>
                        </Information>
                        {isPromocodeApplied ? (
                          <>
                            <Information>
                              <Label>{t('Old Total')}</Label>
                              <OldPrice
                                color="red"
                                textDecoration="line-through"
                              >
                                {originalTotalPrice}
                              </OldPrice>
                            </Information>
                            <Information>
                              <Label>Total After Discount</Label>
                              <OldPrice
                                color="red"
                                textDecoration="line-through"
                              >
                                {totalPrice}
                              </OldPrice>
                            </Information>
                          </>
                        ) : (
                          <Information>
                            <Label>{t('Total')}</Label>
                            <OldPrice
                              color={color}
                              textDecoration={textDecoration}
                            >
                              {totalPrice}
                            </OldPrice>
                          </Information>
                        )}
                        <Information>
                          <Label>{t('Total after Refund')}</Label>
                          <p>{newPrice}</p>
                        </Information>
                      </>
                    ) : !isPromocodeApplied ? (
                      <Information>
                        <Label>{t('Total Price')}</Label>
                        <p>{totalPrice}</p>
                      </Information>
                    ) : (
                      <>
                        <Information>
                          <Label>{t('Total Before Discount')}</Label>
                          <OldPrice
                            color={'red'}
                            textDecoration={'line-through'}
                          >
                            {originalTotalPrice}
                          </OldPrice>
                        </Information>
                        <Information>
                          <Label>{t('Total After Discount')}</Label>
                          <p>{totalPrice}</p>
                        </Information>
                      </>
                    )}
                  </TotalContainer>
                  {!replaceOrder && (
                    <ButtonWrapper>
                      <Button
                        onClick={(e) => {
                          handleOk(e);
                        }}
                      >
                        {loading || loadingOpen ? (
                          <Spin></Spin>
                        ) : (
                          t('Create Order')
                        )}
                      </Button>
                    </ButtonWrapper>
                  )}
                </>
              );
            } else {
              return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
            }
          })()}
        </CartContainer>
      )}
    </>
  );
};

const CartFormWrapper = Form.create({})(Cart);

export default CartFormWrapper;
