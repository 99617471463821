import gql from 'graphql-tag';

export const validCustomer = gql`
  mutation validCustomer($customer_id: ID!) {
    validCustomer(customer_id: $customer_id) {
      id
      name
      phone
      created_at
      updated_at
    }
  }
`;
export const toggleBlock = gql`
  mutation toggleBlock($customer_id: ID!) {
    toggleBlock(customer_id: $customer_id) {
      id
      name
      phone
      created_at
      updated_at
    }
  }
`;
